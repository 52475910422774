import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Customer Experience Preview" prev={{
  name: 'Account Dashboard Intro',
  path: '/account-dashboard-intro'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This preview helps you get an understanding of what your customers will see when they get a review request.`}</p>
    <p>{`Let’s get a few terms straight before we get started.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Term`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Definition`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Review request flow`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The order and timing that review requests are sent to customers. Different types of review requests will sent at different points in the flow.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Review request type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A review request with a particular theme or objective that is sent at a specific spot in the `}<a parentName="td" {...{
              "href": "/help-center/review-request-flow"
            }}>{`review request flow`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Template`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The layout and text a customer sees when they are sent a review request. Each review request type can have multiple templates. Read more about why there are `}<a parentName="td" {...{
              "href": "/help-center/review-request-templates"
            }}>{`multiple templates`}</a>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The Customer Experience Preview section shows every type of review request that is part of the review request flow.`}</p>
    <p>{`You can see the `}<a parentName="p" {...{
        "href": "/help-center/review-request-flow"
      }}>{`review request flow article`}</a>{` for more information on how each type is used.`}</p>
    <p>{`For each type of review request there is a description and a list of templates. Templates for both contact methods (SMS text and email) are shown.`}</p>
    <p>{`Each of the templates listed is a link to an interactive preview of that template. Follow the link to see the customer experience for yourself.`}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
The Google review link Simple RM uses behaves differently for the owners of business listings. Read more about this <Link href="/help-center/google-review-link-not-working" mdxType="Link">here</Link>.
    </Message>
    <p>{`Explore the templates as much as you would like, nothing you do when interacting with the preview will affect any of the stats in your account.`}</p>
    <p>{`This is also the place to see how changes you make to the display settings affect the look of your review requests.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      